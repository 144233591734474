import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';


const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <div style={{justifyContent:"center","display":'flex'}}>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf910hyij8waoT3Z-hiPUfR1g0HMAiN8RhAS6yEg2yv_Lp-gw/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="50">Loading…</iframe>

      </div>

   {/*   <Cta split />*/}
    </>
  );
}

export default Home;